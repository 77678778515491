import React from 'react'
import classnames from 'classnames'
import { Logo } from '@envato/ui'
import styles from './scrolling_product_display.module.css'
import { stringify } from 'query-string'
import Hyperlink from '../hyperlink/hyperlink'
import productPatterns from './product_patterns'

const topRow = ['mixkit', 'market', 'elements']
const bottomRow = ['placeit', 'tutsplus', 'reshot']
const products = {
  market: {
    slug: 'market',
    name: 'Envato Market',
    logo: 'market',
    ...productPatterns.market[0]
  },
  elements: {
    slug: 'elements',
    name: 'Envato Elements',
    logo: 'elements',
    ...productPatterns.elements[0]
  },
  placeit: {
    slug: 'placeit',
    name: 'Placeit by Envato',
    logo: 'placeit',
    ...productPatterns.placeit[0]
  },
  tutsplus: {
    slug: 'tutsplus',
    name: 'Envato Tuts+',
    logo: 'tutsplus',
    ...productPatterns.tutsplus[0]
  },
  mixkit: {
    slug: 'mixkit',
    name: 'Mixkit',
    logo: 'mixkit',
    ...productPatterns.mixkit[0]
  },
  reshot: {
    slug: 'reshot',
    name: 'Reshot',
    logo: 'reshot',
    ...productPatterns.reshot[0]
  }
}

const ScrollingProductDisplay = () => (
  <div className={styles.root}>
    <div className={classnames(styles.boxes, styles.slow)}>
      <div className={styles.animated}>
        {repeatArray(topRow, 3).map((product) => (
          <LogoBox product={product} key={product} />
        ))}
      </div>
    </div>
    <div className={styles.boxes}>
      <div className={styles.animatedOffset}>
        {repeatArray(bottomRow, 2).map((product) => (
          <LogoBox product={product} key={product} />
        ))}
      </div>
    </div>
    <div className={styles.fadeLeft} />
    <div className={styles.fadeRight} />
  </div>
)

const LogoBox = ({ product }) => {
  return (
    <div className={styles.box}>
      <Hyperlink
        href={urls[product]}
        className={styles.item}
        trackingId='scrolling_product_tile'
        trackingLabel={product}
      >
        <Logo whichLogo={products[product].logo} className={styles.logo} alt={products[product].name} />
        {products[product].tagline}
      </Hyperlink>
    </div>
  )
}

const utms = (product) =>
  stringify({
    utm_source: 'envatocom',
    utm_medium: 'promos',
    utm_campaign: `${product}_envatocom_scrollingproductselector`
  })

const urls = {
  mixkit: `https://mixkit.co/?${utms('mixkit')}`,
  market: `https://themeforest.net/?${utms('market')}`,
  elements: `https://elements.envato.com/?${utms('elements')}`,
  placeit: `https://placeit.net/?${utms('placeit')}`,
  tutsplus: `https://tutsplus.com/?${utms('tuts')}`,
  reshot: `https://reshot.com/?${utms('reshot')}`
}

const repeatArray = (array, times) => {
  return Array(times).fill(array).flat()
}

export default ScrollingProductDisplay
