const pattern = (match, deliverable, experience, tagline) => ({
  match,
  deliverable,
  experience,
  tagline
})

/**
 * Pattern template to help you get started when adding a new product to the matrix.
 *
const TEMPLATE = [
  pattern(true, 'any', 'any', `Browse ${MARKET_ITEM_COUNT} digital assets`),
  pattern(false, 'any', 'novice', `Browse ${MARKET_ITEM_COUNT} digital assets`),
  pattern(false, 'any', 'experienced', `Browse ${MARKET_ITEM_COUNT} digital assets`),

  pattern(true, 'website', 'any', `Browse ${MARKET_ITEM_COUNT} digital assets`),

  pattern(true, 'video', 'any', 'Browse video templates & footage'),

  pattern(true, 'mobileApp', 'any', 'Browse app templates & graphics'),

  pattern(true, 'graphic', 'any', 'Browse graphics & illustrations'),

  pattern(true, 'photo', 'any', `Browse ${MARKET_ITEM_COUNT} digital assets`)

  pattern(true, 'musicTrack', 'any', 'Browse royalty-free music'),

  pattern(true, 'designTemplate', 'any', `Browse ${MARKET_ITEM_COUNT} digital assets`)
]
 */

const defaultTaglines = {
  market: 'Buy and sell digital assets',
  elements: 'Unlimited digital assets subscription',
  placeit: 'Create designs, videos and mock-ups',
  tutsplus: 'Learn creative and technical skills',
  mixkit: 'Free assets for your next video project',
  reshot: 'Authentic free stock photos'
}

export default {
  market: [
    pattern(true, 'any', 'any', defaultTaglines.market),
    pattern(false, 'any', 'novice', defaultTaglines.market),
    pattern(false, 'any', 'experienced', defaultTaglines.market),
    pattern(true, 'website', 'any', defaultTaglines.market),
    pattern(false, 'website', 'novice', defaultTaglines.market),
    pattern(true, 'website', 'experienced', defaultTaglines.market),
    pattern(true, 'video', 'any', 'Browse video templates & footage'),
    pattern(true, 'video', 'novice', 'Browse video templates & footage'),
    pattern(true, 'video', 'experienced', 'Browse video templates & footage'),
    pattern(true, 'mobileApp', 'any', 'Browse app templates & graphics'),
    pattern(false, 'mobileApp', 'novice', 'Browse app templates & graphics'),
    pattern(true, 'mobileApp', 'experienced', 'Browse app templates & graphics'),
    pattern(true, 'graphic', 'any', 'Browse graphics & illustrations'),
    pattern(true, 'graphic', 'novice', 'Browse graphics & illustrations'),
    pattern(true, 'graphic', 'experienced', 'Browse graphics & illustrations'),
    pattern(true, 'photo', 'any', defaultTaglines.market),
    pattern(true, 'photo', 'novice', defaultTaglines.market),
    pattern(true, 'photo', 'experienced', defaultTaglines.market),
    pattern(true, 'musicTrack', 'any', 'Browse royalty-free music'),
    pattern(false, 'musicTrack', 'novice', 'Browse royalty-free music'),
    pattern(true, 'musicTrack', 'experienced', 'Browse royalty-free music'),
    pattern(true, 'designTemplate', 'any', defaultTaglines.market),
    pattern(true, 'designTemplate', 'novice', defaultTaglines.market),
    pattern(true, 'designTemplate', 'experienced', defaultTaglines.market)
  ],
  elements: [
    pattern(true, 'any', 'any', defaultTaglines.elements),
    pattern(false, 'any', 'novice', defaultTaglines.elements),
    pattern(false, 'any', 'experienced', defaultTaglines.elements),
    pattern(true, 'website', 'any', defaultTaglines.elements),
    pattern(true, 'website', 'novice', defaultTaglines.elements),
    pattern(true, 'website', 'experienced', defaultTaglines.elements),
    pattern(false, 'video', 'any', defaultTaglines.elements),
    pattern(false, 'video', 'novice', defaultTaglines.elements),
    pattern(true, 'video', 'experienced', defaultTaglines.elements),
    pattern(false, 'mobileApp', 'any', defaultTaglines.elements),
    pattern(false, 'mobileApp', 'novice', defaultTaglines.elements),
    pattern(false, 'mobileApp', 'experienced', defaultTaglines.elements),
    pattern(true, 'graphic', 'any', defaultTaglines.elements),
    pattern(true, 'graphic', 'novice', defaultTaglines.elements),
    pattern(true, 'graphic', 'experienced', defaultTaglines.elements),
    pattern(true, 'photo', 'any', defaultTaglines.elements),
    pattern(true, 'photo', 'novice', defaultTaglines.elements),
    pattern(true, 'photo', 'experienced', defaultTaglines.elements),
    pattern(false, 'musicTrack', 'any', defaultTaglines.elements),
    pattern(false, 'musicTrack', 'novice', defaultTaglines.elements),
    pattern(false, 'musicTrack', 'experienced', defaultTaglines.elements),
    pattern(false, 'designTemplate', 'any', defaultTaglines.elements),
    pattern(false, 'designTemplate', 'novice', defaultTaglines.elements),
    pattern(true, 'designTemplate', 'experienced', defaultTaglines.elements)
  ],
  placeit: [
    pattern(true, 'any', 'any', defaultTaglines.placeit),
    pattern(false, 'any', 'novice', defaultTaglines.placeit),
    pattern(false, 'any', 'experienced', defaultTaglines.placeit),
    pattern(false, 'website', 'any', defaultTaglines.placeit),
    pattern(false, 'website', 'novice', defaultTaglines.placeit),
    pattern(false, 'website', 'experienced', defaultTaglines.placeit),
    pattern(true, 'video', 'any', defaultTaglines.placeit),
    pattern(true, 'video', 'novice', defaultTaglines.placeit),
    pattern(true, 'video', 'experienced', defaultTaglines.placeit),
    pattern(false, 'mobileApp', 'any', defaultTaglines.placeit),
    pattern(false, 'mobileApp', 'novice', defaultTaglines.placeit),
    pattern(false, 'mobileApp', 'experienced', defaultTaglines.placeit),
    pattern(true, 'graphic', 'any', defaultTaglines.placeit),
    pattern(true, 'graphic', 'novice', defaultTaglines.placeit),
    pattern(true, 'graphic', 'experienced', defaultTaglines.placeit),
    pattern(false, 'photo', 'any', defaultTaglines.placeit),
    pattern(false, 'photo', 'novice', defaultTaglines.placeit),
    pattern(false, 'photo', 'experienced', defaultTaglines.placeit),
    pattern(false, 'musicTrack', 'any', defaultTaglines.placeit),
    pattern(false, 'musicTrack', 'novice', defaultTaglines.placeit),
    pattern(false, 'musicTrack', 'experienced', defaultTaglines.placeit),
    pattern(true, 'designTemplate', 'any', defaultTaglines.placeit),
    pattern(true, 'designTemplate', 'novice', defaultTaglines.placeit),
    pattern(true, 'designTemplate', 'experienced', defaultTaglines.placeit)
  ],
  tutsplus: [
    pattern(true, 'any', 'any', defaultTaglines.tutsplus),
    pattern(false, 'any', 'novice', defaultTaglines.tutsplus),
    pattern(false, 'any', 'experienced', defaultTaglines.tutsplus),
    pattern(true, 'website', 'any', defaultTaglines.tutsplus),
    pattern(true, 'website', 'novice', defaultTaglines.tutsplus),
    pattern(true, 'website', 'experienced', defaultTaglines.tutsplus),
    pattern(true, 'video', 'any', 'Learn with video tutorials & courses'),
    pattern(false, 'video', 'novice', 'Learn with video tutorials & courses'),
    pattern(true, 'video', 'experienced', 'Learn with video tutorials & courses'),
    pattern(true, 'mobileApp', 'any', defaultTaglines.tutsplus),
    pattern(false, 'mobileApp', 'novice', defaultTaglines.tutsplus),
    pattern(true, 'mobileApp', 'experienced', defaultTaglines.tutsplus),
    pattern(true, 'graphic', 'any', defaultTaglines.tutsplus),
    pattern(true, 'graphic', 'novice', defaultTaglines.tutsplus),
    pattern(true, 'graphic', 'experienced', defaultTaglines.tutsplus),
    pattern(false, 'photo', 'any', defaultTaglines.tutsplus),
    pattern(false, 'photo', 'novice', defaultTaglines.tutsplus),
    pattern(false, 'photo', 'experienced', defaultTaglines.tutsplus),
    pattern(true, 'musicTrack', 'any', defaultTaglines.tutsplus),
    pattern(true, 'musicTrack', 'novice', defaultTaglines.tutsplus),
    pattern(true, 'musicTrack', 'experienced', defaultTaglines.tutsplus),
    pattern(false, 'designTemplate', 'any', defaultTaglines.tutsplus),
    pattern(false, 'designTemplate', 'novice', defaultTaglines.tutsplus),
    pattern(false, 'designTemplate', 'experienced', defaultTaglines.tutsplus)
  ],
  mixkit: [
    pattern(true, 'any', 'any', defaultTaglines.mixkit),
    pattern(false, 'any', 'novice', defaultTaglines.mixkit),
    pattern(false, 'any', 'experienced', defaultTaglines.mixkit),
    pattern(false, 'website', 'any', defaultTaglines.mixkit),
    pattern(false, 'website', 'novice', defaultTaglines.mixkit),
    pattern(false, 'website', 'experienced', defaultTaglines.mixkit),
    pattern(true, 'video', 'any', defaultTaglines.mixkit),
    pattern(true, 'video', 'novice', defaultTaglines.mixkit),
    pattern(true, 'video', 'experienced', defaultTaglines.mixkit),
    pattern(false, 'mobileApp', 'any', defaultTaglines.mixkit),
    pattern(false, 'mobileApp', 'novice', defaultTaglines.mixkit),
    pattern(false, 'mobileApp', 'experienced', defaultTaglines.mixkit),
    pattern(true, 'graphic', 'any', defaultTaglines.mixkit),
    pattern(true, 'graphic', 'novice', defaultTaglines.mixkit),
    pattern(true, 'graphic', 'experienced', defaultTaglines.mixkit),
    pattern(false, 'photo', 'any', defaultTaglines.mixkit),
    pattern(false, 'photo', 'novice', defaultTaglines.mixkit),
    pattern(false, 'photo', 'experienced', defaultTaglines.mixkit),
    pattern(false, 'musicTrack', 'any', defaultTaglines.mixkit),
    pattern(false, 'musicTrack', 'novice', defaultTaglines.mixkit),
    pattern(false, 'musicTrack', 'experienced', defaultTaglines.mixkit),
    pattern(false, 'designTemplate', 'any', defaultTaglines.mixkit),
    pattern(false, 'designTemplate', 'novice', defaultTaglines.mixkit),
    pattern(false, 'designTemplate', 'experienced', defaultTaglines.mixkit)
  ],
  reshot: [pattern(true, 'any', 'any', defaultTaglines.reshot)]
}
