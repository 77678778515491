import React from 'react'
import { AnchorWithArrow } from '@envato/ui'
import Title from '../title/title'
import ScrollingProductDisplay from '../scrolling_product_display/scrolling_product_display'
import styles from './company_product_section.module.css'
import { frontdoorPaths } from '../../routing/paths'

const CompanyProductSection = () => (
  <div className={styles.root}>
    <div className={styles.wrap}>
      <Title
        royaltyText='Our Products'
        titleText='Our products empower designers and creatives to do their best work'
        supportingText="Whether it's getting projects done faster with creative assets or learning new creative and design skills, we're here to help."
      />
      <div className={styles.cta}>
        <AnchorWithArrow className={styles.anchor} href={frontdoorPaths.products}>
          Browse All Products
        </AnchorWithArrow>
      </div>
    </div>
    <ScrollingProductDisplay />
  </div>
)

export default CompanyProductSection
